<template>
  <v-container
    class="custom-page-header-count pt-0 px-0"
    :class="moduleType == 'leave' ? 'pointer-events-none' : ''"
  >
    <v-layout class="pt-0" style="overflow-x: auto; max-width: 100%">
      <template v-if="dataLoading">
        <template v-for="(data, index) in dataLoadingList">
          <v-flex
            :key="index"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !dataLoadingList[index + 1],
              'mx-1': index != 0 && dataLoadingList[index + 1],
            }"
          >
            <div
              :class="
                'card m-0 top_card card-custom gutter-b border-' +
                data.headercolor
              "
            >
              <div class="card-body p-0">
                <div :class="'card-rounded-bottom mid_part '">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span
                          :class="
                            'svg-icon svg-icon-xl svg-icon-' + data.headercolor
                          "
                        >
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex bTitle text-right">
                      <h5
                        class="text-dark-75 font-weight-bold my-0 min-width-150px mr-5"
                      >
                        <v-skeleton-loader
                          class="custom-skeleton my-0"
                          type="text"
                        ></v-skeleton-loader>
                      </h5>
                      <span
                        :class="
                          'qnt_val font-weight-bolder text-' + data.headercolor
                        "
                      >
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="data.headercolor"
                        ></v-progress-circular>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
      <template v-else>
        <div v-if="false">
          <template v-for="(data, index) in statusList">
            <v-flex
              :key="index"
              class="py-0"
              v-if="data.top_visible"
              :class="{
                'mr-1': index == 0,
                'ml-1': !statusList[index + 1],
                'mx-1': index != 0 && statusList[index + 1],
              }"
            >
              <div
                :class="
                  'card m-0 top_card card-custom gutter-b border-' +
                  data.headercolor
                "
              >
                <div class="card-body p-0">
                  <div
                    :class="
                      'card-rounded-bottom mid_part shadow-sm bg-light-' +
                      data.color +
                      ' bg-front-' +
                      data.textcolor
                    "
                  >
                    <div
                      class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                    >
                      <span
                        class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                      >
                        <span class="symbol-label">
                          <span
                            :class="
                              'svg-icon svg-icon-xl svg-icon-' + data.color
                            "
                          >
                            <inline-svg
                              :src="
                                $assetURL(
                                  'media/custom-svg/' + moduleType + '.svg'
                                )
                              "
                            />
                          </span>
                        </span>
                      </span>
                      <div class="d-flex flex-column text-right bTitle">
                        <router-link
                          :to="
                            getDefaultRoute(moduleType, {
                              query: { status: data.value },
                            })
                          "
                          v-slot="{
                            href,
                            route,
                            navigate,
                            isActive,
                            isExactActive,
                          }"
                        >
                          <a :href="href" v-on:click="navigate">
                            <h5 class="text-dark-75 font-weight-bold mt-2">
                              <!-- {{ data.text }} -->
                              <template
                                v-if="data.value == 'submit_for_approval'"
                              >
                                <template v-if="userDetails.role == 4">
                                  Submied For Approval
                                </template>
                                <template v-else>
                                  Pending For Approval</template
                                >
                              </template>
                              <template v-else> {{ data.text }}</template>
                            </h5>
                            <template v-if="data.value == 'all'">
                              <span
                                :class="
                                  'qnt_val font-weight-bolder text-' +
                                  data.headercolor
                                "
                                >{{ data[allkey] }}</span
                              >
                            </template>
                            <template v-else>
                              <span
                                :class="
                                  'qnt_val font-weight-bolder text-' +
                                  data.headercolor
                                "
                                >{{ data[countkey] }}</span
                              >
                            </template>
                          </a>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
          </template>
        </div>
        <v-row class="mx-auto" style="display: contents !important">
          <template v-for="(data, index) in statusList">
            <v-col class="py-2 px-1" :key="index" v-if="data.top_visible">
              <router-link
                :to="
                  getDefaultRoute(moduleType, {
                    query: { status: data.value },
                  })
                "
                v-slot="{ href, route, navigate, isActive, isExactActive }"
              >
                <a :href="href" v-on:click="navigate">
                  <v-card
                    style="min-width: 215px"
                    :class="getBgColor(data.color)"
                    outlined
                    class="bg-img"
                  >
                    <v-card-text class="py-1">
                      <div class="d-flex justify-space-between">
                        <div>
                          <div class="text-h6 font-weight-bold">
                            <template
                              v-if="data.value == 'submit_for_approval'"
                            >
                              <template v-if="userDetails.role == 4">
                                Submied For Approval
                              </template>
                              <template v-else> Pending For Approval</template>
                            </template>
                            <template v-else> {{ data.text }}</template>
                          </div>
                          <div class="d-flex">
                            <template
                              v-if="
                                data.type == 2 ||
                                data.type == 3 ||
                                data.type == 4 ||
                                data.type == 8 ||
                                data.type == 9 ||
                                data.type == 27
                              "
                            >
                              <inline-svg
                                :fill="
                                  data.value === 'all' || data.value === 'draft'
                                    ? '#00bcd4'
                                    : data.color
                                "
                                width="30"
                                :src="
                                  $assetURL(
                                    'media/custom-svg/' + data.icon + '.svg'
                                  )
                                "
                              />
                            </template>
                            <template v-else>
                              <v-icon size="30" :color="data.color">{{
                                data.icon
                              }}</v-icon>
                            </template>

                            <!-- <template v-else>
                              <v-icon size="30" :color="data.color"
                                >mdi-file-star-outline</v-icon
                              >
                            </template> -->
                            <div
                              class="black--text text-h5 font-weight-bold pl-3"
                              v-if="data.amount > 0"
                            >
                              {{ formatMoney(data.amount) }}
                            </div>
                          </div>
                        </div>
                        <div class="black--text text-h4 font-weight-medium">
                          <template v-if="data.value == 'all'">
                            {{ data[allkey] }}
                          </template>
                          <template v-else>
                            {{ data[countkey] }}
                          </template>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </a>
              </router-link>
            </v-col>
          </template>
          <v-col cols="3" class="py-2" v-if="false">
            <v-card style="background-color: #c8fafa">
              <v-card-text class="py-1">
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="text-h6 font-weight-bold">All Quotations</div>
                    <div class="d-flex">
                      <v-icon size="30" color="cyan"
                        >mdi-file-star-outline</v-icon
                      >
                      <div class="black--text text-h4 font-weight-bold pl-3">
                        $ 323.00
                      </div>
                    </div>
                  </div>
                  <div class="black--text text-h4 font-weight-medium">3</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" class="py-2" v-if="false">
            <v-card elevation="2" style="background-color: #edfbed">
              <v-card-text class="py-1">
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="text-h6 black--text font-weight-medium">
                      Accept
                    </div>
                    <div class="d-flex">
                      <v-icon size="35" color="green"
                        >mdi-file-star-outline</v-icon
                      >
                      <div class="black--text text-h4 font-weight-bold pl-3">
                        $ 323.00
                      </div>
                    </div>
                  </div>
                  <div class="black--text text-h4 font-weight-medium">3</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" class="py-2" v-if="false">
            <v-card elevation="2" style="background-color: #fae4e4">
              <v-card-text class="py-1">
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="text-h6 black--text font-weight-medium">
                      Reject
                    </div>
                    <div class="d-flex">
                      <v-icon size="30" color="red"
                        >mdi-file-star-outline</v-icon
                      >
                      <div class="black--text text-h4 font-weight-bold pl-3">
                        $ 323.00
                      </div>
                    </div>
                  </div>
                  <div class="black--text text-h4 font-weight-medium">3</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" class="py-2" v-if="false">
            <v-card elevation="2" style="background-color: #fff3de">
              <v-card-text class="py-1">
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="text-h6 black--text font-weight-medium">
                      Sent
                    </div>
                    <div class="d-flex">
                      <v-icon size="30" color="orange"
                        >mdi-file-star-outline</v-icon
                      >
                      <div class="black--text text-h4 font-weight-bold pl-3">
                        $ 323.00
                      </div>
                    </div>
                  </div>
                  <div class="black--text text-h4 font-weight-medium">3</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template v-for="(data, index) in extraList">
          <v-flex
            :key="`extra-${index}`"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !extraList[index + 1],
              'mx-1': index != 0 && extraList[index + 1],
            }"
          >
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-primary'">
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right bTitle">
                      <router-link
                        :to="
                          getDefaultRoute(moduleType, {
                            query: { status: data.status_key },
                          })
                        "
                        v-slot="{
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }"
                      >
                        <a :href="href" v-on:click="navigate">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            {{ data.title }}
                          </h5>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                            >{{ data.value }}</span
                          >
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
      <v-menu
        v-if="false"
        max-height="400"
        max-width="250"
        offset-y
        left
        :close-on-content-click="false"
        content-class="white-background"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="dataLoading"
            depressed
            class="mx-2 custom-bold-button btn-tab-hide"
            color="cyan white--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>mdi-table-edit</v-icon>
          </v-btn>
        </template>
        <draggable
          tag="ul"
          v-model="defaultColDefs"
          class="draggable-group"
          handle=".draggable-drag-icon"
        >
          <template v-for="status in statusList">
            <li class="draggable-group-item" :key="status.value">
              <v-checkbox
                dense
                v-model="defaultColShow"
                v-bind:value="status.value"
                :label="status.text"
                color="cyan"
                hide-details
                class="mt-0 mb-0"
              ></v-checkbox>
              <v-icon class="draggable-drag-icon" right color="cyan"
                >mdi-drag</v-icon
              >
            </li>
          </template>
        </draggable>
      </v-menu>
    </v-layout>
  </v-container>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import draggable from "vuedraggable";

export default {
  mixins: [CommonMixin, ListingMixin],
  props: {
    /* dataLoading: {
      type: [Boolean],
      required: true,
      default: false,
    }, */
    moduleType: {
      type: [String],
      required: true,
      default: "customer",
    },
    allkey: {
      type: [String],
      required: true,
      default: "all_customers",
    },
    countkey: {
      type: [String],
      required: true,
      default: "customer_status_count",
    },
    statusList: {
      type: [Array, Object],
      required: true,
      default() {
        return new Array();
      },
    },
    extraList: {
      type: [Array],
      default() {
        return new Array();
      },
    },
  },
  data() {
    return {
      userDetails: {},
      defaultColShow: [],
      dataLoadingList: [
        {
          headercolor: "primary",
          bgcolor: "bg-light-warning",
        },
        {
          headercolor: "primary",
          bgcolor: "bg-light-success",
        },
        {
          headercolor: "primary",
          bgcolor: "bg-light-info",
        },
      ],
    };
  },
  methods: {
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "bg-cyan-lighten5";
        case "green":
          return "bg-green-lighten5";
        case "indigo":
          return "bg-indigo-lighten5";
        case "red":
          return "bg-red-lighten5";
        case "#b71c1c":
          return "bg-darkred-lighten5";
        case "blue":
          return "bg-blue-lighten5";
        case "orange":
          return "bg-orange-lighten5";
        case "#ff7300":
          return "bg-ff7300-lighten5";
        case "yellow":
          return "bg-yellow-lighten5";
        case "purple":
          return "bg-purple-lighten5";
        case "black":
          return "bg-black-lighten5";
        case "grey":
          return "bg-grey-lighten5";
        case "teal":
          return "bg-teal-lighten5";
        default:
          return "white";
      }
    },
    /*  updateVisible() {
      const _this = this;
      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then(({ data }) => {
            this.customThead = data;
            this.getRows();
          });
      });
    }, */
  },
  mounted() {
    this.userDetails = JwtService.currentUser();
  },
  components: {
    draggable,
  },
};
</script>
